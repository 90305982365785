import { Schedule } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';

import InputCounter from '@/components/ui/InputCounter/InputCounter';
import useFormatMessage from '@/hooks/useFormatMessage';

import { Alert } from './HourCounter.style';

const HourCounter = React.forwardRef(
  ({ onBlur, onHourChange, value, name }, ref) => {
    const formatMessage = useFormatMessage();

    return (
      <Alert icon={<Schedule color="primary" />}>
        <InputCounter
          inputRef={ref}
          onChange={onHourChange}
          onBlur={onBlur}
          value={value}
          min={1}
          max={24}
          name={name}
          label={formatMessage('dr_form_hours')}
        />
      </Alert>
    );
  }
);

HourCounter.displayName = 'HourCounter';

HourCounter.propTypes = {
  onBlur: PropTypes.func,
  onHourChange: PropTypes.func,
  value: PropTypes.number,
  name: PropTypes.string,
};

export default HourCounter;
